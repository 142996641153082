<template>
    <div>
        <div>{{itemId}}</div>

    </div>
</template>

<script>
    export default {
        name: "TemplateItem",
        data() {
            return {

            }
        },
        computed: {
            itemId() {
               return  this.$route.params.id
            }

        },
        methods: {

        }
    }
</script>

<style scoped lang="scss">

</style>